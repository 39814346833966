import React,{useState} from 'react';
import './App.css';

import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  clientId:"first-infoplakat-auth",
  realm:"infoplakat",
  url: "https://login.fup.no"
})


keycloak.init({
onLoad:"check-sso",
//silentCheckSsoRedirectUri:window.location.origin+"/silent-check-sso.html"
}).then((auth)=>{

  console.log(`User is ${auth} ? 'authenticated' : 'not authenticated'}`);
  if(auth) {
    keycloak.onTokenExpired=()=>console.log("token expired");
  }else {
    //window.location.reload();
  }

}).catch((err)=>{
  console.error("Failed to initialize keycloak",err)
})
function App() {

  const renderUser = async () => {
    const user = await keycloak.loadUserProfile();
    if(user==null) {
      return null;
    }

    return <div>
      <ul>
        <li><b>Firstname: </b> {user.firstName}</li>
        <li><b>Lastname: </b> {user.lastName}</li>
        <li><b>username: </b> {user.username}</li>
        <li><b>Email: </b> {user.email}</li>
        <li><b>Created At: </b> {user.createdTimestamp!=null ? new Date(user.createdTimestamp).toLocaleString():"NO DATE SET"}</li>
      </ul>
    </div>

  }

  const renderParsedToken =  ()=> {
    const token = keycloak.tokenParsed;
    


    if(token==null) {
      return null;
    }

    return (<div>
      <ul>
        <li><b>Roles: </b> {token.realm_access?.roles.join(", ")}</li>
      </ul>
    </div>);
  }

  const [infoMessage,setInfoMessage]=useState("");
  const [userDetails,setUserDetails]=useState<JSX.Element | null>();
  return (
    <div className="App">
      <header className="App-header">
      
      <h1>My awesome Authentication app</h1>
      </header>

      <section className="App-content">
      <div className="button-wrapper">
        <button onClick={()=>setInfoMessage(`Authenticated: ${keycloak.authenticated? "TRUE":"FALSE"}`)}>Is Authenticated</button>
        <button onClick={()=>keycloak.login()}>Login</button>
        <button onClick={()=>setInfoMessage(keycloak.token ?? "TOKEN IS NOT SET")}>Show access token</button>
        <button onClick={()=>setInfoMessage(JSON.stringify(keycloak.tokenParsed))}>Show Parsed Access Token</button>
        <button onClick={()=>setInfoMessage(`ABOUT TO EXPIRE: ${keycloak.isTokenExpired(5)?"TRUE":"FALSE"}`)}>Check Token Expired</button>
        <button onClick={()=>keycloak.updateToken(10).then((isUpdated)=>setInfoMessage(`TOKEN REFRESHED: ${isUpdated?"TRUE":"FALSE"}`)).catch(e=>setInfoMessage("ERROR UPDATING TOKEN"))}>Update Token, if required</button>
        <button onClick={()=>keycloak.logout({redirectUri:"https://auth.turboken.no"})}>Logout</button>
        <button onClick={async ()=>setUserDetails(await renderUser())}>Render User details</button>
      </div>

      <div className="info">
        <h2>Info Box</h2>

        <p>
          {infoMessage}
        </p>

       {userDetails}
        {renderParsedToken()}

        
      </div>
      </section>
    </div>
  );
}

export default App;
